import mandel2 from "./assets/mandel2.png";
import e58pro from "./assets/e58xpro.webp"

export interface Project {
  title: string;
  url: string;
  description: string

  projectImage?: string;
  languageImage?: string;
}

export interface OSContrib {
  title: string;
  url: string;
  description: string;
}

const ICONS = {"python": "https://www.python.org/static/community_logos/python-logo-master-v3-TM.png",
               "clojure": "https://clojure.org/images/clojure-logo-120b.png",
               "nasm": "https://www.nasm.us/images/nasm.png"};

export const PROJECT_DATA: Project[] = [
  {title: "Mandelbrot Set Explorer",
   projectImage: mandel2,
   languageImage: ICONS['clojure'],
   url: "https://github.com/carcigenicate/mandelbrot",
   description: "A Mandelbrot Set explorer capable of producing detailed fractal images."},

  {title: "E58 Pro Drone Interceptor",
   projectImage: e58pro,
   languageImage: ICONS['python'],
   url: "https://github.com/carcigenicate/E58ProDroneInterception",
   description: "An interception routine capable of detecting vulnerable drones, disconnecting them from their " +
    "pilot, and stealing control of the them using an Xbox 360 Controller."},

  {title: "Hailstone Sequence",
   languageImage: ICONS['nasm'],
   url: "https://github.com/carcigenicate/nasm_hailstone",
   description: "An implementation of the hailstone sequence written in NASM."},

  {title: "Process Injector",
   languageImage: ICONS['python'],
   url: "https://github.com/carcigenicate/process_injector",
   description: "A proof-of-concept program able to inject and run shellcode into a another process."},

  {title: "ARP Spoofer",
   languageImage: ICONS['python'],
   url: "https://github.com/carcigenicate/arp_spoofer",
   description: "A script that uses the Scapy packet-crafting library to spoof ARP packets to setup a " +
     "man-in-the-middle situation, where all traffic being sent between two hosts on a network are sent to your " +
     "machine first."}
];

export const OSContribData: OSContrib[] = [
  {title: "Scapy",
   url: "https://github.com/secdev/scapy/issues/3238",
   description: "I reported a bug that was leading to certain packets being parsed incorrectly, which lead to it being fixed."},

  {title: "The Exploit Database",
   url: "https://github.com/offensive-security/exploitdb/pull/195",
   description: "I submitted a pull request that fixed an error in a exploit written in Ruby."},

  {title: "Quil",
   url: "https://github.com/quil/quil/issues/208",
   description: "I alerted the maintainer of the Quil graphics library that their code was emitting reflection warnings (which can affect performance of the library)."}
];
