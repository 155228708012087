import {PROJECT_DATA, OSContribData} from "../../../dummy_data";

import styles from "./projects.module.scss";

interface ProjectsProps {

}

function Projects(props: ProjectsProps) {
  return (
    <main className={`${styles.outerProjectContainer} containerRow`}>
      <div className={`${styles.projectRows} containerCol`}>
        <h2>Projects</h2>

        {PROJECT_DATA.map((data, index) => {
          return (
            <div className={`card`} key={index}>
              <div className={`${styles.projectRow} containerRow`}>

                {/* TODO: Find a better way to handle the case of no image. A placeholder div seems hacky */}
                {data.projectImage ?
                    <img className={`${styles.projectImage}`} src={data.projectImage} alt={"Project"} />
                    : <div className={`${styles.projectImage}`} />
                }

                <div className={styles.detailSection}>
                  <h3 className={`${styles.projectTitle}`}>
                    <a href={data.url}>
                      {data.title}
                    </a>
                  </h3>
                  <p>{data.description}</p>
                  {data.languageImage &&
                    <img className={`${styles.languageImage}`}
                         src={data.languageImage} alt="Language Icon" />
                  }
                </div>
              </div>
            </div>
          );
        })}

      </div>

      <div className={`${styles.contribRows} containerCol`}>
        <h2>Open-Source Contributions</h2>

        {OSContribData.map((data, index) => {
          return (
            <div className={`card`} key={index}>
              <div className={`${styles.projectRow} containerCol`}>
                <h3 className={`${styles.projectTitle}`}>
                  <a href={data.url}>
                    {data.title}
                  </a>
                </h3>
                <p>{data.description}</p>
              </div>
             </div>
          );
        })}

      </div>
    </main>



  );
}

export default Projects;