import styles from "../home/home.module.scss";

interface ContactProps {

}

function Contact(props: ContactProps) {
    return (
        <main>
          <header>
            <h1>
              Contact Me
            </h1>
          </header>
          <p className={`${styles.lookingForWork}`}>
            I am currently looking for primarily Frontend, Backend, Full-Stack, and
              Python development work; although I am open to other roles as well. I am also open to remote work, as
              well as on-site work in Calgary.</p>
          <p>If you have an employment opportunity, please contact me at the address below:</p>
          <address>
              <a href="mailto:brendonw5@gmail.com">brendonw5@gmail.com</a>
          </address>
          <p>I can also be reached via LinkedIn:</p>
          <a href="https://www.linkedin.com/in/brendon-williams-a44928205/">Brendon Williams</a>
        </main>

    );
}

export default Contact;