import {NavLink} from "react-router-dom";

import styles from "./navbar.module.scss";

export interface NavbarItemProps {
  label: string;
  route: string;
}

export interface NavbarProps {
  navbarItems: NavbarItemProps[];
}

function NavbarItem(props: NavbarItemProps) {
 return (
    <li>
      <NavLink className={({isActive}) => `${styles.navbarItem} ${(isActive ? styles.activeLink : "")}`}
               to={props.route}>
        {props.label}
      </NavLink>
    </li>
  );
}

function Navbar(props: NavbarProps) {
  return (
    <nav className={`containerRow`}>
      <ul className={`containerRow`}>
        {props.navbarItems.map(prop => <NavbarItem key={prop.label} {...prop} />)}
      </ul>
    </nav>
  );
}

export default Navbar;