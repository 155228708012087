import React from 'react';
import ReactDOM from 'react-dom';

import {HashRouter as Router} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import Base from "./components/structure/base/base";

import "./global.scss";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Base />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
