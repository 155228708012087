import {NavLink} from "react-router-dom";

import styles from "./home.module.scss";

interface HomeProps {

}

function Home(props: HomeProps) {
  return (
      <main className={`${styles.mainContainer} containerCol`}>
          <section className={`${styles.paragraphContainer}`}>
              <header><h1>Hello. My name is Brendon, and I'm a developer. I started writing around ten years ago for
                  fun, and have grown my skills purely out of a love of writing code. I enjoy learning new
                  technologies and languages and consequently, can readily pick up new languages as required.</h1>
              </header>
              <p>I created this site to showcase a few of my projects that I'm the proudest of. This site is a SPA
                  created using React.js, and the art is generated using my Mandelbrot Set explorer (see
                  my <NavLink to={'projects'}>Projects page</NavLink>)
              </p>
          </section>

          <section>
              <header>
                  <h2>Qualifications</h2>
              </header>
              <div className={`${styles.splitQualifications}`}>
                  <section>
                      <header>
                          <h3>
                              Experience, Education, and Certifications
                          </h3>
                      </header>
                      <ul>
                          <li>I am currently the Full-Stack developer responsible for the development of the job
                              board <a href="https://www.iquolify.com">iQuolify</a>. The site is in the final stages
                              awaiting launch.
                          </li>
                          <li>I hold a diploma in Information System Security (with Honours) from the <a
                              href="https://www.sait.ca/">Southern Alberta Institute of Technology.</a></li>
                          <li>I am CompTIA Security+ certified (<a
                              href="https://www.credly.com/badges/117e411a-9b11-471d-80e6-16c5717e38fe/public_url"
                              target="_blank" rel="noreferrer">VNF34FXNVL141BK4</a>).
                          </li>
                      </ul>

                  </section>

                  <section>
                      <header>
                          <h3>
                              Background
                          </h3>
                          <ul>
                              <li>I'm a self-taught programmer whose first tastes of writing code were in Junior High
                                  programming Lego Mindstorms in a Robotics class, and reverse-engineering an
                                  open-source version of the game <a href="https://runescape.com">Runescape</a> in order
                                  to host my own variant of the multi-player game.
                              </li>
                              <li>I have written in a number of language including Python, C, JavaScript, TypeScript,
                                  Java, Scala, Haskell. Python and Clojure are by far my favorite languages, and are the
                                  languages I have the most experience with.
                              </li>
                              <li>I frequent Stack Overflow and enjoy sharing the knowledge I have, and learning from
                                  those who are more experienced.
                              </li>
                          </ul>
                      </header>
                  </section>
              </div>
          </section>
      </main>


  );
}

export default Home;