import {Routes, Route} from "react-router-dom";

import Navbar, {NavbarItemProps} from "../navbar/navbar";

import Home from "../../pages/home/home";
import Projects from "../../pages/projects/projects";
import Contact from "../../pages/contact/contact";

import mandel from "../../../assets/mandel.png";

import styling from "./base.module.scss";



const NAVBAR_ITEMS: NavbarItemProps[] = [
  {label: "Home", route: "/"},
  {label: "Projects", route: "/projects"},
  {label: "Contact Me", route: "/contact"},
];

interface BaseProps {

}

function Base(props: BaseProps) {
  return (
  <div className={`containerCol`}>
    <div className={`${styling.bannerWrapper} containerRow`}>
      <img src={mandel} alt={""}/>
      <Navbar navbarItems={NAVBAR_ITEMS} />
    </div>

    <div className={`containerRow`}>
      <div className={`${styling.mainRouterContainer}`}>
        <Routes>
          <Route path="/" element={<Home />}/>

          <Route path="/projects" element={<Projects />} />

          <Route path="/contact" element={<Contact />} />
      </Routes>
      </div>
    </div>
  </div>
  );
}

export default Base;